class SwymCustomButton extends HTMLElement {
  constructor() {
    super();
    if (!window.SwymCallbacks) {
      window.SwymCallbacks = [];
    }
    window.SwymCallbacks.push(this.onSwymLoadCallback.bind(this));
  }

  onSwymLoadCallback(swat) {
    if (!swat) {
      console.error('Swym not initialized properly');
      return;
    }

    // Wait for DOM content to be fully loaded
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => this.init(swat));
    } else {
      this.init(swat);
    }
  }

  init(swat) {
    const updateButtonState = () => {
      // Get the current product ID from the button after any DOM updates
      const productId = this.getAttribute('product-id');
      if (!productId) {
        console.error('Product ID not provided to swym-custom-button');
        return;
      }

      const buttons = this.querySelectorAll('.swym-add-to-wishlist');
      if (!buttons.length) return;

      swat.fetch((products) => {
        const isInWishlist = products.some((item) => item.empi == productId);
        buttons.forEach((button) => {
          const isFilledButton = button.classList.contains('my-icon-filled');
          if (isInWishlist) {
            button.style.display = isFilledButton ? 'block' : 'none';
          } else {
            button.style.display = isFilledButton ? 'none' : 'block';
          }

          // Update button action based on wishlist state
          button.replaceWith(button.cloneNode(true)); // Remove old listeners
          const newButton = this.querySelector(
            `.swym-add-to-wishlist${isFilledButton ? '.my-icon-filled' : ':not(.my-icon-filled)'}`
          );

          newButton.addEventListener('click', async (e) => {
            e.preventDefault();
            const currentProductId = this.getAttribute('product-id'); // Get latest product ID
            const variant = newButton.dataset.variantId;
            const productUrl = newButton.dataset.productUrl;

            if (isInWishlist) {
              // Find the product in the wishlist and remove it
              const product = products.find((item) => item.empi == currentProductId);
              if (product) {
                swat.removeFromWishList(product, updateButtonState);
              }
            } else {
              fetch(`${productUrl}.js`)
                .then((response) => response.json())
                .then((productData) => {
                  swat.addToWishList(
                    {
                      empi: currentProductId,
                      du: productUrl,
                      epi: variant,
                      pr: productData.price / 100,
                      dt: productData.title
                    },
                    variant,
                    updateButtonState
                  );
                })
                .catch((error) => {
                  console.error('Error fetching product data:', error);
                });
            }
          });
        });
      });
    };

    // Initial state update
    updateButtonState();

    const variantSelect = document.querySelector('variant-radios');
    if (variantSelect) {
      variantSelect.addEventListener('change', updateButtonState);
    }

    // Update button state when items are added or removed from wishlist
    swat.evtLayer.addEventListener(swat.JSEvents.addedToWishlist, (event) => {
      const currentProductId = this.getAttribute('product-id');
      if (event.detail.d.empi == currentProductId) {
        updateButtonState();
      }
    });

    swat.evtLayer.addEventListener(swat.JSEvents.removedFromWishlist, (event) => {
      const currentProductId = this.getAttribute('product-id');
      if (event.detail.d.empi == currentProductId) {
        updateButtonState();
      }
    });
  }
}

// Register the custom element
customElements.define('swym-custom-button', SwymCustomButton);

// Hook into Swym's variant change system
window.triggerSwymVariantEvent = function (variant) {
  // This will be called by Swym when variants change
  const buttons = document.querySelectorAll('swym-custom-button');
  buttons.forEach((button) => {
    button.querySelector('.swym-add-to-wishlist').dataset.variantId = variant.id;
  });
};
